import { useCallback, useContext } from 'react';
import type { CompletionStatus } from '@atlassiansox/cross-flow-support';
import { useCrossFlow, Journeys, Targets } from '@atlassiansox/cross-flow-support';

import {
	ExperienceTrackerContext,
	LOOM_CROSS_SELL_EXPERIENCE,
} from '@confluence/experience-tracker';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

type CrossFlowRequestAccessOptions = {
	sourceComponent: string;
};

export const useLoomCrossFlowRequestAccessCallback = ({
	sourceComponent,
}: CrossFlowRequestAccessOptions) => {
	const crossFlow = useCrossFlow();
	const experienceTracker = useContext(ExperienceTrackerContext);
	return useCallback(async () => {
		if (!crossFlow.isEnabled) {
			experienceTracker.fail({
				name: LOOM_CROSS_SELL_EXPERIENCE,
				error: new Error('Loom crossflow is not enabled'),
				attributes: {
					sourceComponent,
					adType: 'crossFlow',
				},
			});
			return;
		}

		experienceTracker.succeed({
			name: LOOM_CROSS_SELL_EXPERIENCE,
			attributes: {
				sourceComponent,
				adType: 'crossFlow',
			},
		});

		let response: CompletionStatus | undefined;
		try {
			response = await crossFlow.api.open({
				journey: Journeys.GET_STARTED,
				sourceComponent,
				sourceContext: 'confluence',
				targetProduct: Targets.LOOM,
			});
		} catch (error: any) {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.CONTENT_TYPES,
			});
		}

		/**
		 * `success` only indicates whether or not the user closed the drawer via the "back arrow"
		 * button. It does *not* indicate that the user succesfully completed cross-flow, however
		 * *if* the user goes through cross-flow, it *will* be `true`
		 */
		if (response?.success) {
			// TODO: re-check Loom provisioning status + update entrypoints + (if co-use) open SDK
			// https://hello.jira.atlassian.cloud/browse/CTIA-2677
		}
	}, [crossFlow, experienceTracker, sourceComponent]);
};
