import type { FC } from 'react';
import React, { useCallback, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { type LoomVideo } from '@loomhq/record-sdk';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/loading-button';
import Tooltip from '@atlaskit/tooltip';

import { fg } from '@confluence/feature-gating';
import { withFlags, type FlagsStateContainer } from '@confluence/flags';
import type { OpenLoomRecorderParams } from '@confluence/loom-utils';
import { useLoomEntryPointVariant, useLoomRecorderEntryPoint } from '@confluence/loom-utils';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import { PageHeaderLoomButtonSpotlight } from '@confluence/loom-onboarding';
import { useSkippableCoordination } from '@confluence/skippable-coordination-client';
import { useEditPageLoadingActions } from '@confluence/load-edit-page/entry-points/EditPageLoadingContext';
import { getUrlForContentType } from '@confluence/content-types-utils';
import {
	ExperienceTrackerContext,
	LOOM_PAGE_HEADER_CONTENT_INSERTION,
	ExperienceTimeout,
} from '@confluence/experience-tracker';
import { type useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import { PageHeaderLoomButtonQuery } from './PageHeaderLoomButtonQuery.graphql';
import type {
	PageHeaderLoomButtonQuery as PageHeaderLoomButtonQueryType,
	PageHeaderLoomButtonQueryVariables,
} from './__types__/PageHeaderLoomButtonQuery';
import { LoomIcon } from './LoomIcon';
import { useLoomInsertUrlState } from './useLoomInsertUrlState';

type PageHeaderLoomButtonComponentProps = {
	contentId: string;
	dataVC?: string;
	label: string;
	spaceKey: string;
	contentType: string;
	flags: FlagsStateContainer;
	ssrPlaceholderIdProp?: ReturnType<typeof useSSRPlaceholderReplaceIdProp>;
};

export enum PostLoomRecordingCTA {
	EDITOR_INSERT = 'editorInsert',
	PAGE_COMMENT_INSERT = 'pageCommentInsert',
	COPY_LINK = 'copyLink',
	OPEN_ON_LOOM = 'openOnLoom',
}

const PageHeaderLoomButtonComponent: FC<PageHeaderLoomButtonComponentProps> = ({
	contentId,
	dataVC,
	label,
	spaceKey,
	contentType,
	flags,
	ssrPlaceholderIdProp,
}) => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { loadEditor } = useEditPageLoadingActions();
	const [, loomInsertUrlActions] = useLoomInsertUrlState();

	const saveLoomUrlAndLoadEditor = (video: LoomVideo) => {
		loomInsertUrlActions.updateFullPageEditorLoomUrl(video);
		const redirectUrl = getUrlForContentType({
			contentType,
			spaceKey,
			contentId,
			redirectToEditor: true,
		});
		loadEditor({
			contentId,
			spaceKey,
			redirectUrl,
			contentType,
		});
	};

	const beginExperienceTracker = (postLoomRecordingCTA: PostLoomRecordingCTA) => {
		experienceTracker.start({
			name: LOOM_PAGE_HEADER_CONTENT_INSERTION,
			timeout: ExperienceTimeout.LOOM_PAGE_HEADER_CONTENT_INSERTION,
			attributes: { postLoomRecordingCTA },
		});
	};

	const entryPointVariant = useLoomEntryPointVariant();

	const { data } = useQuery<PageHeaderLoomButtonQueryType, PageHeaderLoomButtonQueryVariables>(
		PageHeaderLoomButtonQuery,
		{
			variables: { contentId },
		},
	);

	const isContentContributor = Boolean(data?.contentContributors?.isCurrentUserContributor);

	let openLoomRecorderParams: OpenLoomRecorderParams | undefined;
	let postLoomRecordingCTA: PostLoomRecordingCTA = PostLoomRecordingCTA.OPEN_ON_LOOM;
	let resetInsertFunctionOnUnmount = false;

	if (fg('loom_page_header_insert_into_content')) {
		const contentData = data?.content?.nodes?.[0];

		const canEdit = contentData?.operations?.some(
			(operation) => operation?.operation === 'update' && operation?.targetType === contentType,
		);
		const canComment = contentData?.operations?.some(
			(operation) => operation?.targetType === 'comment' && operation?.operation === 'create',
		);

		if (canEdit && isContentContributor) {
			// editor mode insert
			postLoomRecordingCTA = PostLoomRecordingCTA.EDITOR_INSERT;
			openLoomRecorderParams = {
				onInsert: (_, video: LoomVideo) => {
					beginExperienceTracker(postLoomRecordingCTA);
					saveLoomUrlAndLoadEditor(video);
				},
				// intentionally not localized because Loom RecordSDK does not support i18n
				insertButtonText: 'Insert Loom video',
			};
		} else if (canComment) {
			// page comment insert
			postLoomRecordingCTA = PostLoomRecordingCTA.PAGE_COMMENT_INSERT;
			// Set to true so that CTA 'Add to comment' will not render if user has navigated to
			// different page post Loom recording.
			resetInsertFunctionOnUnmount = true;
			openLoomRecorderParams = {
				onInsert: (_, video: LoomVideo) => {
					beginExperienceTracker(postLoomRecordingCTA);
					loomInsertUrlActions.updatePageCommentEditorLoomUrl(video);
					const openPageCommentEditorButton = document.getElementById('addCommentButton');
					openPageCommentEditorButton && openPageCommentEditorButton.click();
				},
				// intentionally not localized because Loom RecordSDK does not support i18n
				insertButtonText: 'Add to comment',
			};
		} else {
			postLoomRecordingCTA = PostLoomRecordingCTA.COPY_LINK;
			// Not defining openLoomRecorderParams here so that
			// undefined will be passed to the Loom SDK resulting
			// in the Loom SDK rendering a single CTA of 'Copy link'
		}
	} else {
		openLoomRecorderParams = {
			onInsert: (videoUrl: string) => window.open(videoUrl, '_blank'),
			// intentionally not localized because Loom RecordSDK does not support i18n
			insertButtonText: 'Open on Loom',
		};
	}

	const isLoomBetaOnboardingEnabled = useBooleanFeatureFlag(
		'confluence.frontend.loom-beta-onboarding_6ne6j',
	);

	const { openLoomRecorder, isOpeningRecorder, isLoomRecorderInitialized, hasRecordingAccess } =
		useLoomRecorderEntryPoint({
			entryPointLocation: 'pageHeader',
			flags,
			resetInsertFunctionOnUnmount,
		});

	// activate Loom Beta onboarding if the necessary feature flags are on and conditions are met
	const shouldActivateLoomBetaOnboarding =
		entryPointVariant === 'BETA' &&
		isLoomBetaOnboardingEnabled &&
		hasRecordingAccess &&
		isLoomRecorderInitialized &&
		isContentContributor;

	const shouldActivateLoomGAOnboarding =
		entryPointVariant === 'CO_USE' && hasRecordingAccess && isLoomRecorderInitialized;

	const [isLoomBetaOnboardingActive, stopLoomBetaOnboarding] = useSkippableCoordination(
		'cc-loom-beta-onboarding-page-header',
		!shouldActivateLoomBetaOnboarding,
	);

	const [isLoomGAOnboardingActive, stopLoomGAOnboarding] = useSkippableCoordination(
		'cc-loom-onboarding-spotlight-editor',
		!(shouldActivateLoomGAOnboarding && fg('confluence_m25_onboarding_spotlights')),
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'createLoomHeaderItem',
				source: 'pageHeader',
				attributes: {
					postLoomRecordingCTA,
				},
			},
		}).fire();

		void openLoomRecorder(openLoomRecorderParams);
	}, [createAnalyticsEvent, openLoomRecorder, openLoomRecorderParams, postLoomRecordingCTA]);

	const handleSpotlightClose = useCallback(() => {
		if (isLoomBetaOnboardingActive) {
			void stopLoomBetaOnboarding();
		} else if (isLoomGAOnboardingActive) {
			void stopLoomGAOnboarding();
		}
	}, [
		stopLoomBetaOnboarding,
		stopLoomGAOnboarding,
		isLoomBetaOnboardingActive,
		isLoomGAOnboardingActive,
	]);

	const LoomButtonWithTooltip = (
		<Tooltip content={label}>
			{(tooltipProps) => (
				<Button
					appearance="subtle"
					iconAfter={<LoomIcon label="" size="medium" />}
					aria-label={label}
					{...tooltipProps}
					onClick={handleClick}
					isLoading={isOpeningRecorder}
					data-vc={dataVC}
					{...ssrPlaceholderIdProp}
				/>
			)}
		</Tooltip>
	);

	if (
		(isLoomBetaOnboardingEnabled && isLoomBetaOnboardingActive && entryPointVariant === 'BETA') ||
		(isLoomGAOnboardingActive &&
			entryPointVariant === 'CO_USE' &&
			fg('confluence_m25_onboarding_spotlights'))
	) {
		return (
			<PageHeaderLoomButtonSpotlight onTryClick={handleClick} onClose={handleSpotlightClose}>
				{LoomButtonWithTooltip}
			</PageHeaderLoomButtonSpotlight>
		);
	}

	return LoomButtonWithTooltip;
};

export const PageHeaderLoomButton = withFlags(PageHeaderLoomButtonComponent);
